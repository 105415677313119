import React, { useState } from 'react';
import './App.css';
import HomeScreen from './components/HomeScreen';
import SignInForm from './components/SignInForm';
import SignOutScreen from './components/SignOutScreen';

function App() {
  // Possible screens: 'home', 'signin', 'signout'
  const [currentScreen, setCurrentScreen] = useState('home');
  
  // Function to navigate between screens
  const navigateTo = (screen) => {
    setCurrentScreen(screen);
  };
  
  // Render the appropriate screen based on currentScreen state
  const renderScreen = () => {
    switch(currentScreen) {
      case 'signin':
        return <SignInForm onBack={() => navigateTo('home')} onSubmit={() => navigateTo('home')} />;
      case 'signout':
        return <SignOutScreen onBack={() => navigateTo('home')} onSignOut={() => navigateTo('home')} />;
      default:
        return <HomeScreen onSignIn={() => navigateTo('signin')} onSignOut={() => navigateTo('signout')} />;
    }
  };
  
  return (
    <div className="app">
      <header className="app-header">
        <div className="container">
          <h1>Thomas Truck Training</h1>
        </div>
      </header>
      <main className="container">
        {renderScreen()}
      </main>
      <footer className="app-footer">
        <div className="container">
          <p>© {new Date().getFullYear()} Thomas Truck Training Digital Register</p>
        </div>
      </footer>
    </div>
  );
}

export default App;