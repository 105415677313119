import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://your-api-gateway-url.amazonaws.com/prod';

// For development/testing without a backend
const useMockApi = process.env.REACT_APP_USE_MOCK === 'true' || !process.env.REACT_APP_API_URL;

// Mock data for testing without a backend
let mockVisitors = [
  {
    PK: "9b37cad0-df86-4ff6-b440-28963d169214",
    action: "signIn",
    course: "CB Novice",
    name: "Dean Brown",
    signinTime: "2025-03-05T08:59:35.150Z",
    status: "in"
  },
  {
    PK: "8c45dab1-ef27-5aa7-c551-39074e278325",
    action: "signIn",
    course: "Advanced Programming",
    name: "Jane Smith",
    signinTime: "2025-03-05T09:15:22.310Z",
    status: "in"
  },
  {
    PK: "7d32cab1-ef13-4aa7-c551-29074e378421",
    action: "signIn",
    course: "Design Fundamentals",
    name: "Sam Wilson",
    signinTime: "2025-03-05T10:20:15.112Z",
    status: "in"
  },
  {
    PK: "6e21bac2-df26-3bb8-d662-18063d487532",
    action: "signIn",
    course: "UX Research",
    name: "Alex Johnson",
    signinTime: "2025-03-05T11:05:42.223Z",
    signoutTime: "2025-03-05T15:30:45.222Z",
    status: "out"
  }
];

// Simulates API delay for mock data
const mockDelay = () => new Promise(resolve => setTimeout(resolve, 300));

// Fetch all visitors
export const fetchVisitors = async () => {
  if (useMockApi) {
    await mockDelay();
    return [...mockVisitors];
  }

  try {
    const response = await axios.get(`${API_URL}/visitors`);
    return response.data;
  } catch (error) {
    console.error('Error fetching visitors:', error);
    throw error;
  }
};

// Get visitor status by name
export const getVisitorByName = async (name) => {
  if (useMockApi) {
    await mockDelay();
    const visitor = mockVisitors.find(v => 
      v.name.toLowerCase() === name.toLowerCase() && v.status === 'in'
    );
    return visitor || null;
  }

  try {
    const response = await axios.get(`${API_URL}/visitors/status?name=${encodeURIComponent(name)}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null; // Visitor not found
    }
    console.error('Error getting visitor status:', error);
    throw error;
  }
};

// Sign in a visitor
export const signIn = async (visitorData) => {
  if (useMockApi) {
    await mockDelay();
    const newVisitor = {
      PK: crypto.randomUUID(),
      action: "signIn",
      course: visitorData.course,
      name: visitorData.name,
      signinTime: new Date().toISOString(),
      status: "in"
    };
    mockVisitors.push(newVisitor);
    return newVisitor;
  }

  try {
    const response = await axios.post(`${API_URL}/visitors/signin`, visitorData);
    return response.data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};

// Sign out a visitor
export const signOut = async (name) => {
  if (useMockApi) {
    await mockDelay();
    const visitorIndex = mockVisitors.findIndex(v => 
      v.name.toLowerCase() === name.toLowerCase() && v.status === 'in'
    );
    
    if (visitorIndex !== -1) {
      mockVisitors[visitorIndex] = {
        ...mockVisitors[visitorIndex],
        status: 'out',
        signoutTime: new Date().toISOString()
      };
      return mockVisitors[visitorIndex];
    }
    throw new Error('Visitor not found or not signed in');
  }

  try {
    const response = await axios.post(`${API_URL}/visitors/signout`, { name });
    return response.data;
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};