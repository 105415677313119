import React, { useState } from 'react';
import { signIn } from '../api/visitorApi';

const SignInForm = ({ onBack, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    course: ''
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await signIn(formData);
      setMessage({ type: 'success', text: 'Signed in successfully!' });
      
      // Reset form
      setFormData({
        name: '',
        course: ''
      });
      
      // Wait a moment to show success message, then return to home
      setTimeout(() => {
        onSubmit();
      }, 1500);
      
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to sign in. Please try again.' });
      console.error("Sign in error:", error);
      setLoading(false);
    }
  };

  return (
    <div className="sign-in-screen">
      <div className="screen-header">
        <h2>Sign In</h2>
        <button className="back-button" onClick={onBack}>
          ← Back
        </button>
      </div>
      
      {message && (
        <div className={`message ${message.type}`}>
          {message.text}
        </div>
      )}
      
      <form className="big-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Your Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
            disabled={loading}
            autoFocus
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="course">Course Name</label>
          <input
            type="text"
            id="course"
            name="course"
            value={formData.course}
            onChange={handleChange}
            placeholder="Enter your course name"
            required
            disabled={loading}
          />
        </div>
        
        <div className="form-actions">
          <button 
            type="submit" 
            className="submit-button"
            disabled={loading || !formData.name || !formData.course}
          >
            {loading ? 'Signing In...' : 'Complete Sign In'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;