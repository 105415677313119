import React from 'react';

const HomeScreen = ({ onSignIn, onSignOut }) => {
  return (
    <div className="home-screen">
      <div className="welcome-message">
        <h2>Welcome</h2>
        <p>Please select an option:</p>
      </div>
      
      <div className="big-buttons">
        <button 
          className="big-button sign-in"
          onClick={onSignIn}
        >
          <span className="icon">✓</span>
          <span className="button-text">Sign In</span>
          <span className="description">I'm arriving</span>
        </button>
        
        <button 
          className="big-button sign-out"
          onClick={onSignOut}
        >
          <span className="icon">✗</span>
          <span className="button-text">Sign Out</span>
          <span className="description">I'm leaving</span>
        </button>
      </div>
      
      <div className="help-text">
        <p>
          Touch <strong>Sign In</strong> when you arrive or <strong>Sign Out</strong> when you leave
        </p>
      </div>
    </div>
  );
};

export default HomeScreen;