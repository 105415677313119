import React, { useState, useEffect } from 'react';
import { fetchVisitors, signOut } from '../api/visitorApi';

const SignOutScreen = ({ onBack, onSignOut }) => {
  const [visitors, setVisitors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [signingOut, setSigningOut] = useState(false);
  const [message, setMessage] = useState(null);

  const loadVisitors = async () => {
    setLoading(true);
    try {
      const data = await fetchVisitors();
      // Filter to show only signed-in visitors
      const activeVisitors = data.filter(visitor => visitor.status === 'in');
      setVisitors(activeVisitors);
      setError(null);
    } catch (err) {
      setError('Could not load visitors. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadVisitors();
  }, []);

  const handleSignOut = async (name) => {
    setSigningOut(true);
    try {
      await signOut(name);
      setMessage({ type: 'success', text: 'Signed out successfully!' });
      
      // Refresh the list
      loadVisitors();
      
      // Wait a moment then return to home if no more visitors
      setTimeout(() => {
        if (visitors.length <= 1) { // If this was the last or only visitor
          onSignOut();
        } else {
          setSigningOut(false);
          setMessage(null);
        }
      }, 1500);
      
    } catch (error) {
      setSigningOut(false);
      setMessage({ type: 'error', text: 'Failed to sign out. Please try again.' });
      console.error("Sign out error:", error);
    }
  };

  return (
    <div className="sign-out-screen">
      <div className="screen-header">
        <h2>Sign Out</h2>
        <button className="back-button" onClick={onBack}>
          ← Back
        </button>
      </div>
      
      {message && (
        <div className={`message ${message.type}`}>
          {message.text}
        </div>
      )}
      
      <div className="visitor-list-container">
        {loading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : visitors.length === 0 ? (
          <div className="no-visitors">
            <p>No visitors currently signed in.</p>
            <button className="back-button centered" onClick={onBack}>
              Return to Home
            </button>
          </div>
        ) : (
          <div className="visitor-list">
            <p className="instruction">Please select your name to sign out:</p>
            <ul>
              {visitors.map((visitor) => (
                <li key={visitor.PK}>
                  <button 
                    className="visitor-button"
                    onClick={() => handleSignOut(visitor.name)}
                    disabled={signingOut}
                  >
                    <div className="visitor-info">
                      <span className="visitor-name">{visitor.name}</span>
                      <span className="visitor-course">{visitor.course}</span>
                    </div>
                    <div className="sign-out-icon">→</div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignOutScreen;